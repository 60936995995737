import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CopyToClipboard from './CopyToClipboard';
import { Alert, Box, Button, Checkbox, FormControlLabel, TableHead, Tooltip } from '@mui/material';
import { LocalTime } from '../utils/localTime';
import { DataEnhancement } from '../models/DataEnhancement';
import { useWalletBalance } from '../hooks/useWalletBalance';
import { User } from '../models/UsersResponse';
import { CartItem, ShoppingCart, ShoppingCartResponse } from '../models/Store';
import { genUniqueId, generateOrderID } from '../utils/GenerateUniqueID';
import { Product } from '../models/Products';
import { Storefront } from '../models/StorefrontResponse';
import { APIEndpoint, EndpointType, httpPostNoAuth } from '../utils/apiService';
import { useNavigate } from 'react-router-dom';
import { CheckoutResponse } from '../models/CheckoutResponse';

interface Props {
    dataEnhancement: DataEnhancement | undefined
    customer: User | undefined
    close(): void
    product: Product
    storefront: Storefront
}

export default function DataEnhancementDetails(props: Props) {
    const [subTotalCost, setSubTotalCost] = React.useState(0);
    const [totalCost, setTotalCost] = React.useState(0);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [walletApplied, setWalletApplied] = useState(false)
    const [customerID, setCustomerID] = useState("");
    const [walletCredits, setWalletCredits] = useState(0)
    const [alertError, setAlertError] = useState("")
    
    // Hooks
    const { walletBalance } = useWalletBalance(customerID, 0)
    const navigate = useNavigate();
    
    useEffect(() => {
        setCustomerID(props.customer?.id || "")
    }, [])

    useEffect(() => {
        calcTotalRecordsCost()
    }, [walletApplied])

    useEffect(() => {
        if (walletBalance && walletBalance.balance && walletBalance.balance > 0) {
            setWalletApplied(true)
        }
    }, [walletBalance])

    // const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //     minimumFractionDigits: 0,
    //     maximumFractionDigits: 0,
    // });

    // function makeDefined(input: number | undefined) {
    //     if (input == undefined) {
    //       return 0
    //     }

    //     return input
    // }

    // const detailsStyle = {
    //     position: 'absolute',
    //     top: '40%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 800,
    //     bgcolor: '#ffffff',
    //     border: '1px solid #5c5c5e',
    //     boxShadow: 24,
    //     p: 1,
    //     height: 800,
    // };

    const calcTotalRecordsCost = () => {
        if (props.dataEnhancement && props.dataEnhancement.enhancementResult && props.product) {
            console.log("product: ", props.product)
            const totalRecords = props.dataEnhancement.enhancementResult.enhancedCount
            setTotalRecords(totalRecords)
            
            const subTotalCost = props.product.price * totalRecords
            setSubTotalCost(subTotalCost)

            let credits = 0
            if (walletApplied) {
                if (getWalletBalance() >= totalRecords) {
                    credits = totalRecords
                    setTotalCost(0)
                } else {
                    credits = getWalletBalance()
                    const creditCost = credits * props.product.price
                    setTotalCost(subTotalCost - creditCost)
                }
            } else {
                setTotalCost(subTotalCost)
            }
            setWalletCredits(credits)
        }
    }

    const getWalletBalance = (): number => {
        if (walletBalance && walletBalance.balance) {
            return walletBalance.balance
        }

        return 0
    }

    // const getAllDataPrice = (count: number): string => {
    //     if (count == 0) {
    //         return "N/A"
    //     }

    //     return formatter.format(count * 0.30)
    // }


    // const getSingleDataPrice = (count: number): string => {
    //     if (count == 0) {
    //         return "N/A"
    //     }

    //     return formatter.format(count * 0.10)
    // }

    // const handleBuyAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setBuyAll(event.target.checked);

    //     if (event.target.checked) {
    //         setBuyEmails(false)
    //         setBuyLandline(false)
    //         setBuyWireless(false)
    //         setBuyLandlineNoDNC(false)
    //         setBuyWirelessNoDNC(false)
    //     }
    // };

    // const handleBuyLandlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setBuyLandline(event.target.checked);

    //     if (buyAll && event.target.checked) {
    //         setBuyAll(false)
    //     }
    // };

    // const handleBuyWirelessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setBuyWireless(event.target.checked);

    //     if (buyAll && event.target.checked) {
    //         setBuyAll(false)
    //     }
    // };

    // const handleBuyEmailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setBuyEmails(event.target.checked);

    //     if (buyAll && event.target.checked) {
    //         setBuyAll(false)
    //     }
    // };

    // const handleBuyLandlineNoDNCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setBuyLandlineNoDNC(event.target.checked);

    //     if (buyAll && event.target.checked) {
    //         setBuyAll(false)
    //     }
    // };

    // const handleBuyWirelessNoDNCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setBuyWirelessNoDNC(event.target.checked);

    //     if (buyAll && event.target.checked) {
    //         setBuyAll(false)
    //     }
    // };

    const handleApplyWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWalletApplied(event.target.checked);
    };

    const placeOrder = () => {
        setAlertError("")
        const cartItems: CartItem[] = []

        const cartItem: CartItem = {
            id: props.dataEnhancement?.id || "",
            product: props.product,
            quantity: totalRecords,
            postalCodes: [],
            suppressionCheck: {
                checkCompleted: true,
                availableCount: 0,
                requestedCount: 0,
                isCountFulfilled: true
            }
        }

        cartItems.push(cartItem)

        const cart: ShoppingCart = {
            items: cartItems,
            cartToken: genUniqueId(),
            createdAt: "",
            modifiedAt: "",
            hubspotDealID: "",
            status: "checkout",
            userID: customerID,
            subtotal: subTotalCost,
            total: totalCost,
            couponCode: "",
            discount: 0,
            storefrontID: props.storefront.id,
            discountDescription: "",
            orderID: generateOrderID(),
            applyWallet: walletApplied,
            walletCreditsApplied: walletCredits
        }

        console.log("Cart: " + JSON.stringify(cart))

        if (totalCost == 0) {
        const apiURL = APIEndpoint(EndpointType.StoreCart)
        httpPostNoAuth(apiURL, JSON.stringify(cart))
            .then((data) => {
                const shoppingCartResponse = data as ShoppingCartResponse;

                if (shoppingCartResponse.status == 'error') {
                    setAlertError("Error saving shopping cart: " + shoppingCartResponse.errorMessage)
                } else {
                    navigate("/checkoutsuccess")
                }
            })
            .catch((error) => {
                console.log("saveShoppingCart: " + error.message)
            });
        } else {
            console.log("requesting order...")
            const apiURL =  APIEndpoint(EndpointType.Checkout)
            httpPostNoAuth(apiURL, JSON.stringify(cart))
            .then((data) => {
                const checkoutResponse = data as CheckoutResponse;
                
                if (checkoutResponse.status == 'error') {
                    console.log("Error checking-out: " + checkoutResponse.errorMessage)
                    setAlertError("Error checking out: " + checkoutResponse.errorMessage)
                } else {
                    console.log("Checkout session created successfuly...redirecting to checkout page")
                    window.location.replace(checkoutResponse.redirectURL);
                }
            })
            .catch((error) => { 
                console.log("saveShoppingCart: " + error.message)
            });
        }
    }


    // const getUnitPrice = (): number => {
    //     if (buyAll) {
    //         return 0.3
    //     } 
    //     return .1
    // }

    // const getStripePriceID = (): string => {
    //     const env = getEnvironment()
    //     if (env === "prod") {
    //         if (buyAll) {
    //             return "price_1Pe02FCxNi5DvLVryGG6JvYy"
    //         } else {
    //             return "price_1Pe00PCxNi5DvLVrlBclPwaP"
    //         }
    //     } else {
    //         if (buyAll) {
    //             return "price_1PeHasCxNi5DvLVrWZ0YuaUL"
    //         } else {
    //             return "price_1PeHYnCxNi5DvLVr3pLJNLp7"
    //         }
    //     }
    // }

    // const getStripeProductID = (): string => {
    //     const env = getEnvironment()
    //     if (env === "prod") {
    //         if (buyAll) {
    //             return "prod_QV029tNLRnMQsq"
    //         } else {
    //             return "prod_QV007ATPNxrHwu"
    //         }
    //     } else {
    //         if (buyAll) {
    //             return "prod_QVIB8h3QTNC0wd"
    //         } else {
    //             return "prod_QVI97tn61a96YQ"
    //         }
    //     }
    // }
    
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <Grid sx={{ height: 800, overflow: "scroll" }}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <Typography sx={{ marginLeft: .5, marginTop: -.6, color: "#000000" }} variant='h6'>Data Enhancement Details</Typography>
                <IconButton sx={{ marginTop: -.5, color: "#000000" }} size="small" type="button" onClick={() => props.close()} >
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Grid sx={{ marginTop: 0 }}>
                <Stack>
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Enhancement ID:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.id} <CopyToClipboard value={props.dataEnhancement && props.dataEnhancement.id} /></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Date:</TableCell>
                                        <TableCell>
                                            <Tooltip title={LocalTime(props.dataEnhancement && props.dataEnhancement.createdAt)} placement="bottom-start">
                                                <Typography variant="body2">{props.dataEnhancement && props.dataEnhancement.createdAt}</Typography>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Submitted Filename:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.fileUploadDetails.length > 0 && props.dataEnhancement.fileUploadDetails[0].submittedFilename}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Submitted File Size:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.fileUploadDetails.length > 0 && props.dataEnhancement.fileUploadDetails[0].size} bytes</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Include Phones on DNC:</TableCell>
                                        { props.dataEnhancement && props.dataEnhancement.fileUploadDetails.length > 0 && props.dataEnhancement.fileUploadDetails[0].includeDNC
                                            ? <TableCell>Yes</TableCell>
                                            : <TableCell>No</TableCell>
                                        }
                                    </TableRow>                                    
                                    <TableRow>
                                        <TableCell>Submitted File Record Count:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.fileUploadDetails.length > 0 && props.dataEnhancement.fileUploadDetails[0].records}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Processed File Record Count:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.enhancementResult && props.dataEnhancement.enhancementResult.processedCount}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>No Data Available Count:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.enhancementResult && props.dataEnhancement.enhancementResult.noResultsCount}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Typography sx={{ marginLeft: .5, marginTop: 1, color: "#000000" }} variant='h6'>Enhancement Summary:</Typography>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Category</TableCell>
                                        <TableCell># Records</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>All Data:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.enhancementResult && props.dataEnhancement.enhancementResult.enhancedCount} ({props.dataEnhancement && props.dataEnhancement.enhancementResult && Math.round(props.dataEnhancement.enhancementResult.enhancedCount/props.dataEnhancement.enhancementResult.processedCount*100)}% of all processed records)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Land Line Phones:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.enhancementResult && props.dataEnhancement.enhancementResult.landlineCount} ({props.dataEnhancement && props.dataEnhancement.enhancementResult && Math.round(props.dataEnhancement.enhancementResult.landlineCount/props.dataEnhancement.enhancementResult.enhancedCount*100)}% of all matched data)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Land Line Phones (No DNC):</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.enhancementResult && props.dataEnhancement.enhancementResult.landlineCount - props.dataEnhancement.enhancementResult.landlineDNCCount} ({props.dataEnhancement && props.dataEnhancement.enhancementResult && Math.round((props.dataEnhancement.enhancementResult.landlineCount - props.dataEnhancement.enhancementResult.landlineDNCCount)/props.dataEnhancement.enhancementResult.enhancedCount*100)}% of all matched data)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Cell Phones:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.enhancementResult && props.dataEnhancement.enhancementResult.wirelessCount} ({props.dataEnhancement && props.dataEnhancement.enhancementResult && Math.round(props.dataEnhancement.enhancementResult.wirelessCount/props.dataEnhancement.enhancementResult.enhancedCount*100)}% of all matched data)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Cell Phones (No DNC):</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.enhancementResult && props.dataEnhancement.enhancementResult.wirelessCount - props.dataEnhancement.enhancementResult.wirelessDNCCount} ({props.dataEnhancement && props.dataEnhancement.enhancementResult && Math.round((props.dataEnhancement.enhancementResult.wirelessCount - props.dataEnhancement.enhancementResult.wirelessDNCCount)/props.dataEnhancement.enhancementResult.enhancedCount*100)}% of all matched data)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Emails:</TableCell>
                                        <TableCell>{props.dataEnhancement && props.dataEnhancement.enhancementResult && props.dataEnhancement.enhancementResult.emailCount} ({props.dataEnhancement && props.dataEnhancement.enhancementResult && Math.round(props.dataEnhancement.enhancementResult.emailCount/props.dataEnhancement.enhancementResult.enhancedCount*100)}% of all matched data)</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {props.dataEnhancement?.status === "PURCHASED" 
                        ? <Grid>
                            <Typography sx={{ marginLeft: .5, marginTop: 1, color: "#000000" }} variant='h6'>Order Summary:</Typography>
                            <Typography sx={{ marginLeft: .5, marginTop: 1, color: "#000000" }}>This enhancement has been purchased and is available for download in 'My Purchases'</Typography></Grid>
                        : <Grid>
                        
                        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                            <Paper sx={{ width: 1, p: 1, display: "flex", flexDirection: "column", background: (theme) => theme.palette.background.paper }}>
                                <Stack direction="row" alignItems="flex-start">
                                    <Typography sx={{ width: "150px" }}>Total Records:</Typography>
                                    <Typography>{totalRecords}</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="flex-start">
                                    <Typography sx={{ width: "150px" }}>Sub-Total:</Typography>
                                    <Typography>{formatter.format(subTotalCost)}</Typography>
                                    <Typography variant="caption" sx={{ml:1,mt:.25}}>($0.20/each)</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="flex-start">
                                    <FormControlLabel control={<Checkbox checked={walletApplied} onChange={handleApplyWalletChange} />} label="Use Wallet" sx={{ mt: -1, width: "145px" }} />
                                    <Box>{walletCredits}</Box>
                                    <Tooltip title="Wallet cost is 1 credit for each enhanced record" placement="bottom-start">
                                        <Typography variant="caption" sx={{ml:1,mt:.25}}>[Wallet Price Info]</Typography>
                                    </Tooltip>
                                </Stack>
                                <Stack direction="row" alignItems="flex-start">
                                    <Typography sx={{ width: "150px" }}>Total:</Typography>
                                    <Typography>{formatter.format(totalCost)}</Typography>
                                </Stack>
                                <Button disabled={totalRecords===0} variant="contained" color="primary" sx={{ width: 150, marginTop: "8px" }} onClick={placeOrder}>Place Order</Button>
                                {alertError != "" &&
                                    <Alert severity="error" sx={{ display: alertError ? "block" : "none" }}>{alertError}</Alert>
                                }
                            </Paper>
                        </Stack>
                        </Grid>}
                    </React.Fragment>
                </Stack>
            </Grid>
        </Grid>

    );
}
