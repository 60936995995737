import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Copyright from "../components/CopyRight";
import * as React from "react";
import Title from '../components/Title';
import TextField from "@mui/material/TextField";
import useAuth from "../hooks/useAuth";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AutocompleteUser, useUsers } from "../hooks/useUsers";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import { useWalletBalance } from "../hooks/useWalletBalance";
import { genUniqueId, generateOrderID } from "../utils/GenerateUniqueID";
import { useBlueprints } from "../hooks/useBlueprints";
import { Blueprint, QueryBlueprint } from "../models/Blueprint";
import { useBlueprintCounts } from "../hooks/useBlueprintCounts";
import { CircularProgress, Tooltip } from "@mui/material";
import { ShoppingCart, CartItem, ShoppingCartResponse } from "../models/Store";
import { useProductsAll } from "../hooks/useProductsAll";
import { Product } from "../models/Products";
import { APIEndpoint, EndpointType, getEnvironment, httpPostNoAuth } from "../utils/apiService";
import { useZipCodeCounts } from "../hooks/useZipCodeCounts";
import { Storefront } from "../models/StorefrontResponse";
import { User } from "../models/UsersResponse";

export interface ProductItem {
  id: string;
  blueprintID?: string;
  productID?: string;
  description: string;
  quantity : number;
  leadPrice: string;
  totalPrice: string;
  zipCodes: string[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
  storefront: Storefront
  customer: User | undefined
}

const NewOrder = (props: Props) => {
  const { user  } = useAuth();
  const [newCustomerDisplay, setNewCustomerDisplay] = useState("none")
  const [existingCustomerDisplay, setExistingCustomerDisplay] = useState("inline")
  const [productZips, setProductZips] = useState<string[]>([])
  const [productItemQuantity, setProductItemQuantity] = useState(0)
  const [disableProductPricePerLeadEdit, setDisableProductPricePerLeadEdit] = useState(false)
  const [productPricePerLead, setProductPricePerLead] = useState(".2")
  const [productPrice, setProductPrice] = useState("0")
  const [productItems, setProductItems] = useState<ProductItem[]>([])
  const [productID, setProductID] = useState("")
  const [showAddProductWarning, setShowAddProductWarning] = useState("none")
  const [addProductWarningMsg, setAddProductWarningMsg] = useState("")
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [allowEdit, setAllowEdit] = useState(false);
  const [editingID, setEditingID] = useState("");
  const [showEditButtons, setShowEditButtons] = useState("none")
  const [showAddButton, setShowAddButton] = useState("inline")
  const [orderSubTotal, setOrderSubTotal] = useState(0)
  const [orderSubTotalFormatted, setOrderSubTotalFormatted] = useState("$0.00")
  const [orderSubTotalQuantity, setOrderSubTotalQuantity] = useState(0)
  const [orderTotal, setOrderTotal] = useState(0)
  const [orderTotalFormatted, setOrderTotalFormatted] = useState("$0.00")
  const [orderPercentDiscount, setOrderPercentDiscount] = useState("0")
  const [orderFixedDiscount, setOrderFixedDiscount] = useState("0")
  const [orderDiscount, setOrderDiscount] = useState(0)
  const [orderDiscountFormatted, setOrderDiscountFormatted] = useState("$0.00")
  const [customerID, setCustomerID] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [hubspotDealID, setHubspotDealID] = useState("")
  const [showOrderSubmitWarning, setShowOrderSubmitWarning] = useState("none")
  const [orderSubmitWarningMsg, setOrderSubmitWarningMsg] = useState("")
  const [userInfo, setUserInfo] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")
  const [blueprintID, setBlueprintID] = useState("")
  const [filteredBlueprints, setFilteredBlueprints] = useState<Blueprint[]>([])
  const [blueprintCountRequest, setBlueprintCountRequest ] = useState<QueryBlueprint>();
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([])
  const [walletApplied, setWalletApplied] = useState(false)
  const [walletCredits, setWalletCredits] = useState(0)
  const [productZipCountCheck, setProductZipCountCheck] = useState(0)
  const [zipCountZips, setZipCountZips] = useState("")  
  const [zipCountsRefreshKey, setZipCountsRefreshKey] = useState(0)
  const [showZipSelection, setShowZipSelection] = useState(true)
  const [showCheckCounts, setShowCheckCounts] = useState(false)
  const [blueprintScopes, setBlueprintScopes] = useState<string[]>([])

  const { blueprintCount, blueprintCountLoading, blueprintCountError, blueprintSuppressionCount } = useBlueprintCounts(blueprintCountRequest, true, customerID)
  const { blueprints } = useBlueprints(1, blueprintScopes)
  const { users, autocompleteUsers } = useUsers(0);
  const { walletBalance } = useWalletBalance(customerID, 0)
  const { productsAll } = useProductsAll("tsg", 1)
  const { zipCodeCounts, zipCodeError, zipCodeLoading} = useZipCodeCounts(zipCountZips, "", "", "", true, zipCountsRefreshKey, customerID)

  const productColumns = [
    { field: 'description', headerName: 'Blueprint/Product Name', width: 300 },
    { field: 'quantity', headerName: 'Quantity', width: 70 },
    { field: 'leadPrice', headerName: 'Price', width: 70 },
    { field: 'totalPrice', headerName: 'Total', width: 84},
  ]

  const handleChange = () => {
    if (newCustomerDisplay == 'inline') {
      setNewCustomerDisplay('none')
      setExistingCustomerDisplay('inline')
    } else {
      setNewCustomerDisplay('inline')
      setExistingCustomerDisplay('none')
    }
  };

  useEffect(() => {
    const scopes: string[] = []

    if (props.customer && props.customer.id) {
        scopes.push(props.customer.id)
    }

      scopes.push("global")
      scopes.push("store")

    setBlueprintScopes(scopes)
  }, [])

  useEffect(() => {
    if (blueprints == undefined) {
      return
    }

    setFilteredBlueprints(blueprints.filter(bp => bp.access.scope == "user"))
  }, [blueprints])

  useEffect(() => {
    if (zipCodeCounts == undefined || zipCodeCounts.length == 0) {
      return
    }

    let audienceID = 0
    for (const product of productsAll) {
      if (product.id == productID) {
        audienceID = product.audienceID
        console.log("Found audienceID: " + audienceID)
        break
      }
    }

    if (zipCodeCounts.length > 0) {
      for (const listCount of zipCodeCounts[0].listCounts) {
        if (listCount.audienceID == audienceID) {
          setProductZipCountCheck(listCount.count)
          setProductItemQuantity(listCount.count)
          return
        }
      }
    }
    
    setProductZipCountCheck(0)
  }, [zipCodeCounts])

  useEffect(() => {
    if (productsAll == undefined) {
      return
    }

    const filteredProducts = productsAll.filter(p => p.productType == "one_time")

    // Add Wallet Recharge products
    filteredProducts.push(...getWalletProducts())
    setFilteredProducts(filteredProducts)
  }, [productsAll])

  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/neworder']);
    _hsq.push(['trackPageView']);
  }, []);

  useEffect(() => {
    calcOrderDiscount()
  }, [walletApplied]);

  useEffect(() => {
    calcProductPrice()
 }, [productItemQuantity, productPricePerLead]);

  useEffect(() => {
    calcOrderSubTotal()
  }, [productItems])

  useEffect(() => {
    if (productItemQuantity == 0) {
      setProductItemQuantity(blueprintCount)
    }
  }, [blueprintCount])

  useEffect(() => {
    calcOrderDiscount()
  }, [orderSubTotal, orderFixedDiscount, orderPercentDiscount])

  const customerIDChanged = (event: React.SyntheticEvent<Element, Event>, value: AutocompleteUser | null) => {
    if (value == null) {
      setCustomerID("")
    } else {
      setCustomerID(value.id)

      // Find the user get their phone/email
      for (const user of users) {
        if (user.id == value.id) {
          let phone: string = "none";

          if (user.phone != "") {
            phone = user.phone;
          }
          setUserInfo("Phone: " + phone + ", Email: " + user.email)
        }
      }
    }
  }

  function deleteProducts() {
    for (const item of selectedProducts) {
      setProductItems(oldValues => {
        return oldValues.filter(i => i.id !== item)
      })
    }
    setAllowEdit(false)
    setSelectedProducts([])

    if (editingID != "") {
      setEditingID("");
      setShowEditButtons("none");
      setShowAddButton("inline");
    }
  }

  function editProduct() {
    for (const product of productItems) {
      for (const id of selectedProducts) {
        if (product.id == id) {
          console.log("Editing product: " + JSON.stringify(product))
          if (product.productID) {
            setProductID(product.productID)
            setBlueprintID("")
            setProductZips(product.zipCodes)
          } else if (product.blueprintID) {
            setBlueprintID(product.blueprintID)
            setProductID("")
          }
          setProductItemQuantity(product.quantity);
          setProductPricePerLead(product.leadPrice);
          setShowEditButtons("inline");
          setShowAddButton("none");
          setEditingID(product.id);
          break;
        }
      }
    }
  }

  const itemQuantityChanged = (event: React.ChangeEvent<{ value: string }>) => {
    const qty = parseInt(event.target.value)
    setProductItemQuantity(qty)
  }

  const pricePerLeadChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setProductPricePerLead(event.target.value)
  }

  const orderDiscountPercentChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setOrderPercentDiscount(event.target.value)
  }

  const firstNameChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setFirstName(event.target.value)
  }

  const lastNameChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setLastName(event.target.value)
  }

  const phoneChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setPhone(event.target.value)
  }

  const hubspotDealIDChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setHubspotDealID(event.target.value)
  }

  const emailChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setEmail(event.target.value)
  }

  const orderDiscountFixedChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setOrderFixedDiscount(event.target.value)
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const cancelProductSubmit = () => {
    setEditingID("");
    setShowEditButtons("none");
    setShowAddButton("inline");
  }

  const addProductSubmit = () => {
    // Validate all field requirements are met

    if (isEmptyOrSpaces(blueprintID) && isEmptyOrSpaces(productID)) {
      setAddProductWarningMsg("The 'List Blueprint' or 'Product' must be specified")
      setShowAddProductWarning('inline')
      return
    }

    const price = parseFloat(productPricePerLead)
    if (isNaN(price) || price <= 0) {
      setAddProductWarningMsg("The 'Price/Lead' must be greater than 0")
      setShowAddProductWarning('inline')
      return
    }

    if (productID != "" && showZipSelection && productZips.length == 0) {
      setAddProductWarningMsg("At least one zip code must be specified")
      setShowAddProductWarning('inline')
      return
    }

    const newProduct: ProductItem = {
      id: "", // will get set later
      zipCodes: [],
      blueprintID: "",
      productID: "",
      description: "",
      quantity: productItemQuantity,
      leadPrice: productPricePerLead,
      totalPrice: productPrice,
    }
    
    if (blueprintID != "") {
      newProduct.blueprintID = blueprintID
      newProduct.description = getBlueprintName(blueprintID)
    } else {
      newProduct.productID = productID
      newProduct.zipCodes = productZips
      const selectedProduct = filteredProducts.find(p => p.id == productID)
      if (selectedProduct) {
        newProduct.description = selectedProduct.name
      }
    }
    setShowAddProductWarning('none')

    if (editingID == "") {
      newProduct.id = Math.floor(Math.random() * 10000).toString();
      setProductItems([...productItems, newProduct]);
    } else {
      newProduct.id = editingID;
      for (const i in productItems) {
        if (productItems[i].id == editingID) {
          const newAry = [...productItems];
          newAry[i] = newProduct;
          setProductItems(newAry);
          setEditingID("");
          setShowEditButtons("none");
          setShowAddButton("inline");
        }
      }
    }

    console.log("New Product: " + JSON.stringify(newProduct))
  }

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    resetNewOrderForm()
    setOpenSnackbar(false);
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  function calcProductPrice() {
    const qty: number = productItemQuantity
    const price: number = parseFloat(productPricePerLead)

    if (isNaN(qty) || isNaN(price)) {
      setProductPrice("0")
    } else {
      const total = Math.round(qty * price * 100) / 100 
      setProductPrice(formatter.format(total))
    }
  }

  function isEmptyOrSpaces(str: string){
    return str === null || str.match(/^ *$/) !== null;
  }

  function calcOrderSubTotal() {
    let subTotal = 0.0
    let quantity = 0
    for (const product of productItems) {
      const qty: number = product.quantity
      const price: number = parseFloat(product.leadPrice)
      if (!isNaN(qty) && !isNaN(price)) {
        subTotal += (qty * price);
        quantity += qty;
      } else {
        console.log("WARNING: Could not add price for product id#" + product.id + " with value of " + product.totalPrice)
      }
    }

    const total = Math.round(subTotal * 100) / 100
    setOrderSubTotal(total);
    setOrderSubTotalFormatted(formatter.format(total));
    setOrderSubTotalQuantity(quantity);
  }

  function calcOrderDiscount() {
    let perc = parseFloat(orderPercentDiscount)
    let fixed = parseFloat(orderFixedDiscount)

    if (isNaN(perc)) {
      perc = 0
    }

    if (isNaN(fixed)) {
      fixed = 0
    }

    let total = orderSubTotal
    let credits = 0
    if (walletApplied) {
      if (getWalletBalance() >= orderSubTotalQuantity) {
        credits = orderSubTotalQuantity
      } else {
        credits = getWalletBalance()
      }

      total = total - (credits * .2)
      if (total < 0) {
        total = 0
      }
    }

    let discount = 0
    if (total > 0) {
      discount = (total * (perc/100)) + fixed
    }

    setOrderDiscount(discount);
    setOrderDiscountFormatted(formatter.format(discount));
    setOrderTotal(total - discount)
    setOrderTotalFormatted(formatter.format(total - discount))
    setWalletCredits(credits)
  }

  const validateEmail = (email: string):boolean => {
    const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email)
  }

  const placeOrder = () => {
    setShowOrderSubmitWarning("none")

    if (productItems.length == 0) {
      setOrderSubmitWarningMsg("At least one product must be added to the order")
      setShowOrderSubmitWarning("inline")
      return
    }

    if (newCustomerDisplay == "inline") {
      if (email == "" || phone == "") {
        setOrderSubmitWarningMsg("Customer email and phone must be specified")
        setShowOrderSubmitWarning("inline")
        return
      }

      if (!validateEmail(email)) {
        setOrderSubmitWarningMsg("The email address does not appear to be valid")
        setShowOrderSubmitWarning("inline")
      }

    } else {
      if (customerID == "") {
        setOrderSubmitWarningMsg("A customer must be selected")
        setShowOrderSubmitWarning("inline")
        return
      }
    }

    const cartItems: CartItem[] = []

    if (blueprints == undefined) {
      console.log("Blueprints not loaded yet")
      return
    }

    for (const item of productItems) {
      const bp = blueprints.find(bp => bp.id == item.blueprintID)
      const prod = filteredProducts.find(p => p.id == item.productID)
      if (!bp && !prod) {
        console.log("Could not find blueprint (" + item.blueprintID + ") or a product (" + item.productID + "")
        continue
      }
      
      const cartItem: CartItem = {
        id: genUniqueId(),
        product: createOrGetProduct(prod, bp, item),
        quantity: item.quantity,
        postalCodes: [],
        suppressionCheck: {
          checkCompleted: true,
          availableCount: 0,
          requestedCount: 0,
          isCountFulfilled: true
        }
      }

      if (prod) {
        cartItem.postalCodes = item.zipCodes
      }

      cartItems.push(cartItem)
    }

    const cart: ShoppingCart = {
      items: cartItems,
      cartToken: genUniqueId(),
      createdAt: "",
      modifiedAt: "",
      hubspotDealID: hubspotDealID,
      status: "checkout",
      userID: customerID,
      subtotal: orderSubTotal,
      total: orderTotal,
      couponCode: "",
      discount: orderDiscount,
      storefrontID: "tsg",
      discountDescription: "",
      orderID: generateOrderID(),
      applyWallet: walletApplied,
      walletCreditsApplied: walletCredits
    }

    if (newCustomerDisplay == "inline") {
      cart.newUser = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phone: phone
      }
    }

    console.log("Cart: " + JSON.stringify(cart))

    const apiURL =  APIEndpoint(EndpointType.StoreCart)
    httpPostNoAuth(apiURL, JSON.stringify(cart))
    .then((data) => {
        const shoppingCartResponse = data as ShoppingCartResponse;
        
        if (shoppingCartResponse.status == 'error') {
          setOrderSubmitWarningMsg("Error saving shopping cart: " + shoppingCartResponse.errorMessage)
          setShowOrderSubmitWarning("inline")
        } else {
          setSnackbarMsg("Order placed successfully!")
          setOpenSnackbar(true)
          resetNewOrderForm
        }
    })
    .catch((error) => { 
        console.log("saveShoppingCart: " + error.message)
    });    

  }

  const createOrGetProduct = (prod: Product | undefined, bp: Blueprint | undefined, productItem: ProductItem): Product => {
    if (prod) {
      return prod
    }

    if (!bp) {
      console.log("Could not find blueprint or product")
      return {
        audienceID: 0,
        description: "",
        detailedDescription: "",
        id: "",
        name: "",
        price: 0,
        productType: "",
        listCounts: [],
        active: false,
        stripeID: "",
        createdAt: "",
        modifiedAt: "",
        groupName: "",
        infoURL: "",
        priceInfo: {
          price: 0,
          stripePriceID: ""
        },
        outputFields: [],
        features: {
          noDNC: false,
          phonesEmails: false,
        },
        stripePrice: "",
        leadType: "",
      }
    }

    return {
      audienceID: 0,
      description: bp.name,
      detailedDescription: bp.name,
      id: bp.id,
      name: bp.name,
      price: parseFloat(productItem.leadPrice),
      productType: "custom_leads",
      listCounts: [],
      active: true,
      stripeID: getStripeCustomProductID(),
      createdAt: "",
      modifiedAt: "",
      groupName: "",
      infoURL: "",
      priceInfo: {
        price: parseFloat(productItem.leadPrice),
        stripePriceID: ""
      },
      outputFields: [],
      features: {
        noDNC: false,
        phonesEmails: false,
      },
      stripePrice: "",
      leadType: "",
    }
  }

  const getBlueprintName = (id: string | undefined):string => {
    if (blueprints) {
      for (const bp of blueprints) {
        if (bp.id == id) {
          return bp.name
        }
      }
    }

    return ""
  }

  const getWalletBalance = ():number => {
    if (walletBalance && walletBalance.balance) {
      return walletBalance.balance
    }

    return 0
  }

  const blueprintChanged = (event: SelectChangeEvent) => {
    setBlueprintID(event.target.value as string)

    // Deselect value from products field
    if (productID != "") {
      setProductID("")
    }

    setDisableProductPricePerLeadEdit(false)
    setShowZipSelection(false)
    setShowCheckCounts(true)
  }

  const productChanged = (event: SelectChangeEvent) => {
    setProductID(event.target.value as string)

    // Deselect value from blueprints field
    if (blueprintID != "") {
      setBlueprintID("")
    }

    // Set the price/lead
    for (const product of filteredProducts) {
      if (product.id == event.target.value) {
        setProductPricePerLead(product.priceInfo.price.toString())
        console.log("Product price: " + product.priceInfo.price, "Disabled: " + disableProductPricePerLeadEdit)
        setDisableProductPricePerLeadEdit(true)
        setShowZipSelection(product.productType == "one_time")
        setShowCheckCounts(product.productType == "one_time")
      }
    }
  }

  const verifyCountsClicked = () => {
    if (blueprintID == "" && productID == "") {
      setSnackbarMsg("No List Blueprint or Product selected...please select a List Blueprint or Product first")
      setOpenSnackbar(true)
      return
    }

    // if (customerID == "") {
    //   setSnackbarMsg("No customer selected...please select a customer first")
    //   setOpenSnackbar(true)
    //   return
    // }

    if (productID != "" && productZips.length == 0) {
      setSnackbarMsg("No zip codes specified...plesae specify at least one zip code")
      setOpenSnackbar(true)
      return
    }

    if (blueprintID != "") {
      const selectedBlueprint = blueprints?.find(bp => bp.id == blueprintID)
      if (selectedBlueprint) {
        setBlueprintCountRequest(selectedBlueprint?.queryBlueprint)
      }
    } else if (productID != "") {
      setZipCountZips(productZips.join(","))
      setZipCountsRefreshKey(zipCountsRefreshKey + 1)
    }
  }

  const getStripeCustomProductID = ():string => {
    if (getEnvironment() == "dev") {
      return "prod_Pv636v3I3I6hgr"
    }

    return "prod_Pv65QabwUkOvYl"
  }

  const handleApplyWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWalletApplied(event.target.checked);
  };

  function resetNewOrderForm() {
    setCustomerID("")
    setFirstName("")
    setLastName("")
    setPhone("")
    setEmail("")
    setBlueprintID("")
    setProductZips([])
    setProductItemQuantity(0)
    setProductPricePerLead(".2")
    setProductItems([])
    setOrderPercentDiscount("")
    setOrderFixedDiscount("")
  }

  const getWalletProducts = ():Product[] => {
    if (getEnvironment() == "dev") {
      return [
        {
          audienceID: 0,
          description: "Wallet Recharge - $0.18",
          detailedDescription: "Addition of leads to wallet",
          id: "dev_wallet_18",
          name: "Wallet Recharge - $0.18/Lead",
          price: 18,
          productType: "wallet",
          listCounts: [],
          active: true,
          stripeID: getStripeCustomProductID(),
          createdAt: "",
          modifiedAt: "",
          groupName: "",
          infoURL: "",
          priceInfo: {
            price: .18,
            stripePriceID: "price_1OXb5gCxNi5DvLVr5AGamR7k"
          },
          outputFields: [],
          features: {
            noDNC: false,
            phonesEmails: false,
          },
          stripePrice: "",
          leadType: "Add",
        },
        {
          audienceID: 0,
          description: "Wallet Recharge - $0.16",
          detailedDescription: "Addition of leads to wallet",
          id: "dev_wallet_16",
          name: "Wallet Recharge - $0.16/Lead",
          price: 16,
          productType: "wallet",
          listCounts: [],
          active: true,
          stripeID: getStripeCustomProductID(),
          createdAt: "",
          modifiedAt: "",
          groupName: "",
          infoURL: "",
          priceInfo: {
            price: .16,
            stripePriceID: "price_1OXb7PCxNi5DvLVrXDt64p92"
          },
          outputFields: [],
          features: {
            noDNC: false,
            phonesEmails: false,
          },
          stripePrice: "",
          leadType: "Add",
        },
        {
          audienceID: 0,
          description: "Wallet Recharge - $0.14",
          detailedDescription: "Addition of leads to wallet",
          id: "dev_wallet_14",
          name: "Wallet Recharge - $0.14/Lead",
          price: 16,
          productType: "wallet",
          listCounts: [],
          active: true,
          stripeID: getStripeCustomProductID(),
          createdAt: "",
          modifiedAt: "",
          groupName: "",
          infoURL: "",
          priceInfo: {
            price: .14,
            stripePriceID: "price_1OXb85CxNi5DvLVro9L6Nk9I"
          },
          outputFields: [],
          features: {
            noDNC: false,
            phonesEmails: false,
          },
          stripePrice: "",
          leadType: "Add",
        }
      ]
    } 
      
    return [
      {
        audienceID: 0,
        description: "Wallet Recharge - $0.18",
        detailedDescription: "Addition of leads to wallet",
        id: "prod_wallet_18",
        name: "Wallet Recharge - $0.18/Lead",
        price: 18,
        productType: "wallet",
        listCounts: [],
        active: true,
        stripeID: getStripeCustomProductID(),
        createdAt: "",
        modifiedAt: "",
        groupName: "",
        infoURL: "",
        priceInfo: {
          price: .18,
          stripePriceID: "price_1Oa2A9CxNi5DvLVrAN7cA7vX"
        },
        outputFields: [],
        features: {
          noDNC: false,
          phonesEmails: false,
        },
        stripePrice: "",
        leadType: "Add",
      },
      {
        audienceID: 0,
        description: "Wallet Recharge - $0.16",
        detailedDescription: "Addition of leads to wallet",
        id: "prod_wallet_16",
        name: "Wallet Recharge - $0.16/Lead",
        price: 16,
        productType: "wallet",
        listCounts: [],
        active: true,
        stripeID: getStripeCustomProductID(),
        createdAt: "",
        modifiedAt: "",
        groupName: "",
        infoURL: "",
        priceInfo: {
          price: .16,
          stripePriceID: "price_1Oa2A9CxNi5DvLVrXezfN5H6"
        },
        outputFields: [],
        features: {
          noDNC: false,
          phonesEmails: false,
        },
        stripePrice: "",
        leadType: "Add",
      },
      {
        audienceID: 0,
        description: "Wallet Recharge - $0.14",
        detailedDescription: "Addition of leads to wallet",
        id: "prod_wallet_14",
        name: "Wallet Recharge - $0.14/Lead",
        price: 16,
        productType: "wallet",
        listCounts: [],
        active: true,
        stripeID: getStripeCustomProductID(),
        createdAt: "",
        modifiedAt: "",
        groupName: "",
        infoURL: "",
        priceInfo: {
          price: .14,
          stripePriceID: "price_1Oa2A9CxNi5DvLVrLiHA6LrU"
        },
        outputFields: [],
        features: {
          noDNC: false,
          phonesEmails: false,
        },
        stripePrice: "",
        leadType: "Add",
      }
    ]
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Title>New Order</Title>
            <Grid item xs={12}>
                <Stack  direction="column"  alignItems="flex-start" spacing={2}>
                  {/*Sales Associate and Date*/}
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
                    <TextField id="outlined-basic" label="Sales Associate" variant="outlined" value={user.attributes.name} disabled />
                    <TextField id="outlined-basic" label="HubSpot Deal ID" variant="outlined" onChange={hubspotDealIDChanged} value={hubspotDealID} />
                  </Stack>

                  {/*Customer*/}
                  <Paper sx={{ width: 1, p: 2, display: "flex", flexDirection: "column" }} >
                    <Stack>
                      <Typography sx={{marginLeft: "4px", fontWeight: "bold"}}>Customer Information:</Typography>
                      <FormControlLabel control={<Switch onChange={handleChange} />} label="Create a new customer" />
                      <Box display={existingCustomerDisplay}>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center">
                          <Autocomplete disablePortal isOptionEqualToValue={(option: AutocompleteUser, value: AutocompleteUser) => option.id === value.id} id="existingCustomer" onChange={customerIDChanged} options={autocompleteUsers} size="small"  sx={{ width: 400 }}
                              renderInput={(params) => <TextField {...params} label="Name" /> }
                          />
                          <Chip label={"Wallet Balance: " + getWalletBalance()} sx={{ml:2}}></Chip>
                        </Stack>
                        <Typography sx={{}}>{userInfo}</Typography>
                      </Box>
                      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} display={newCustomerDisplay}>
                        <TextField id="outlined-basic" label="First Name" variant="outlined" size="small" required onChange={firstNameChanged} value={firstName} />
                        <TextField id="outlined-basic" label="Last Name" variant="outlined" size="small" required onChange={lastNameChanged} value={lastName} />
                      </Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} display={newCustomerDisplay} sx={{marginTop: "8px"}}>
                        <TextField id="outlined-basic" label="Phone" variant="outlined" size="small" required onChange={phoneChanged} value={phone} />
                        <TextField id="outlined-basic" label="Email" variant="outlined" size="small" required onChange={emailChanged} value={email}/>
                      </Stack>
                    </Stack>
                  </Paper>

                  {/*Orders Details*/}
                  <Paper sx={{ width: 1, p: 2, display: "flex", flexDirection: "column" }} >
                    <form onSubmit={addProductSubmit}>
                      <Stack spacing={2}>
                          <Typography sx={{fontWeight: "bold"}}>Order Details:</Typography>
                          <Stack direction="row"  alignItems="center" spacing={2} justifyContent="flex-start">
                            <FormControl sx={{width: 300}}>
                              <InputLabel id="lead-type-select-label" size="small">List Blueprint</InputLabel>
                              <Select labelId="lead-type-select-label" size="small" 
                                  id="lead-type-select"
                                  value={blueprintID}
                                  label="List Blueprint"
                                  onChange={blueprintChanged}>
                                    { filteredBlueprints.map((bp) => (
                                      <MenuItem value={bp.id} key={bp.id}>{bp.name}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>

                            <Typography>or</Typography>
                            <FormControl sx={{width: 300}}>
                              <InputLabel id="lead-type-select-label" size="small">Products</InputLabel>
                              <Select labelId="lead-type-select-label" size="small" 
                                  id="lead-type-select"
                                  value={productID}
                                  label="List Blueprint"
                                  onChange={productChanged}>
                                    { filteredProducts.map((fp) => (
                                      <MenuItem key={fp.id} value={fp.id}>{fp.name}</MenuItem>
                                    ))}
                              </Select>
                            </FormControl>
                          </Stack>

                            {showZipSelection &&
                              <Tooltip title="Select zip codes (separate multiple zips with commas)" arrow>
                                <TextField id="outlined-basic" label="Zip Codes" variant="outlined" size="small" value={productZips.join(',')} onChange={(e) => setProductZips(e.target.value.split(','))} />
                              </Tooltip>
                            }

                            {showCheckCounts &&
                              <Stack direction="row"  alignItems="flex-start" spacing={2} sx={{marginTop: "8px"}} >
                                <Button variant="contained" color="primary" sx={{ width: 150, marginTop: "8px" }} onClick={verifyCountsClicked}>Check Counts</Button>
                                { blueprintCountLoading || zipCodeLoading
                                  ? <CircularProgress color="secondary" />
                                  : blueprintCountError || zipCodeError
                                  ? <Typography>Error loading counts: {blueprintCountError}</Typography>
                                  : blueprintID != ""
                                    ? <Alert severity="info">Quantity Available: {blueprintCount} (Based on {blueprintSuppressionCount} previous orders suppressed)</Alert>
                                    : <Alert severity="info">Quantity Available: {productZipCountCheck} (Not based on suppressions)</Alert>
                                }
                              </Stack>
                            }
                          {/* Order Type */}
                          <Stack direction="row"  alignItems="flex-start" spacing={2} sx={{marginTop: "8px"}} >
                            <TextField size="small" value={productItemQuantity} id="outlined-basic" required label="Quantity" variant="outlined" sx={{ width: 123 }} onChange={itemQuantityChanged} />
                            <TextField size="small" id="outlined-basic" value={productPricePerLead} disabled={disableProductPricePerLeadEdit} required label="Price/Lead" variant="outlined" sx={{ width: 123 }} onChange={pricePerLeadChanged} />
                            <TextField size="small" id="outlined-basic" value={productPrice} label="Total Price" variant="outlined" sx={{ width: 123 }} disabled />
                          </Stack>
                          <Stack direction="row"  alignItems="flex-start" spacing={2} sx={{marginTop: "8px"}} >
                            <Box display={showAddButton}>
                              <Button variant="contained" color="primary" sx={{ width: 150, marginTop: "8px" }} onClick={addProductSubmit}>Add Product</Button>
                            </Box>
                            <Box display={showEditButtons}>
                              <Button variant="contained" color="primary" sx={{ width: 150, marginTop: "8px" }} onClick={addProductSubmit}>Save Product</Button>
                              <Button variant="contained" color="primary" sx={{ width: 150, marginTop: "8px", marginLeft: "8px" }} onClick={cancelProductSubmit}>Cancel</Button>
                            </Box>
                            <Box display={showAddProductWarning}>
                              <Alert severity="warning">{addProductWarningMsg}</Alert>
                            </Box>
                          </Stack>
                      </Stack>
                    </form>
                  </Paper>
                  <Paper sx={{ width: 1, p: 2, display: "flex", flexDirection: "column",  background: (theme) => theme.palette.background.paper }}>
                    <Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{marginBottom: 1}}
                      >
                        <Typography sx={{fontWeight: "bold"}}>Selected Products:</Typography>
                        <Box>
                          <Button size="small" variant="outlined" onClick={editProduct} disabled={allowEdit}>
                            <EditIcon color="primary" />Edit
                          </Button>
                          <Button size="small" variant="outlined" onClick={deleteProducts} sx={{marginLeft: 1}}>
                            <DeleteForeverIcon color="primary" />Delete
                          </Button>
                        </Box>
                      </Stack>
                      <React.Fragment>
                      {productItems.length
                      
                      ?<DataGrid
                          columnHeaderHeight={25}
                          rowHeight={25}
                          rows={productItems}
                          columns={productColumns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 20 },
                            },
                          }}
                          hideFooterSelectedRowCount
                          hideFooter
                          checkboxSelection
                          
                          onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const arrayIDs: string[] = [];
                            selectedIDs.forEach(v => arrayIDs.push(v.toString()));
                            if (arrayIDs.length == 1) { setAllowEdit(false) } else { setAllowEdit(true)}
                            setSelectedProducts(arrayIDs)
                          }}
                        />
                        :
                        <Typography sx={{marginLeft: "4px"}}>No products are selected. Add a product above!</Typography>
                      }
                      </React.Fragment>
                    </Stack>
                  </Paper>

                  {/* Order Summary */}
                  <Paper sx={{ width: 1, p: 2, display: "flex", flexDirection: "column",  background: (theme) => theme.palette.background.paper }}>
                    <Typography sx={{fontWeight: "bold"}}>Order Summary:</Typography>
                    <Stack direction="row"  alignItems="flex-start">
                      <Typography sx={{width: "150px"}}>List Quantity:</Typography>
                      <Typography>{orderSubTotalQuantity}</Typography>
                    </Stack>
                    <Stack direction="row"  alignItems="flex-start">
                      <Typography sx={{width: "150px"}}>Sub-Total:</Typography>
                      <Typography>{orderSubTotalFormatted}</Typography>
                    </Stack>
                    <Stack direction="row" >
                      <Typography sx={{width: "150px", marginTop: "9px"}}>Discount:</Typography>
                      <Typography sx={{marginTop: "9px", width: "80px"}}>{orderDiscountFormatted}</Typography>
                      <TextField size="small" label="Percent" id="outlined-basic" variant="outlined" sx={{ width: 150, marginLeft: "4px" }} onChange={orderDiscountPercentChanged} value={orderPercentDiscount} />
                      <TextField size="small" label="Fixed $ Amount" id="outlined-basic" variant="outlined" sx={{ width: 150, marginLeft: "8px" }} onChange={orderDiscountFixedChanged} value={orderFixedDiscount}/>
                    </Stack>
                    <Stack direction="row" alignItems="flex-start">
                      <FormControlLabel control={<Checkbox checked={walletApplied} onChange={handleApplyWalletChange} />} label="Use Wallet" sx={{mt:-1, width:"145px"}}/>
                      <Box>{walletCredits}</Box>
                    </Stack>
                    <Stack direction="row"  alignItems="flex-start">
                      <Typography sx={{width: "150px"}}>Total:</Typography>
                      <Typography>{orderTotalFormatted}</Typography>
                    </Stack>
                    <Button variant="contained" color="primary" sx={{ width: 150, marginTop: "8px" }} onClick={placeOrder}>Submit Order</Button>
                    <Box display={showOrderSubmitWarning}>
                      <Alert severity="warning">{orderSubmitWarningMsg}</Alert>
                    </Box>
                    <Snackbar
                      open={openSnackbar}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}
                      message={snackbarMsg}
                      action={snackbarAction}
                    />
                   </Paper> 
                </Stack>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default NewOrder;